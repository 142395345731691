import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <div>
      <h2>Coming Soon</h2>
      <Link to="/">~Just hold on, we're going home~</Link>
    </div>
  );
};

export default Blog;
